import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import CategoryTile from './categoryTile'

const query = graphql`
{
  allStrapiCategory(filter: {level: {eq: 1},name:{ne:"Others"}}){
    nodes {
      name
      slug
      path
      image {
         localFile {
             childImageSharp {
                 gatsbyImageData
             } 
         }
       }
    }
  }
}
`

const Range = props => {
    const data = useStaticQuery(query);
    const { allStrapiCategory: {
        nodes: categories
    } } = data
    const { title } = props


    const order = [
        "Power Tools",
        "Abrasives",
        "Ladders And Access",
        "Material Handling Equipment",
        "Safety And Workwear",
        "Hand Tools",
        "Testing Equipment",
        "Tool Storage",
        "Measuring And Layout Tools",
        "Surveying Equipment",
        "Electrical Tools",
    ] 
    categories?.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));

    // if (mainCategory.length > 1) {
        // Remove the last  element and push it to the first
        // const firstMatchingElement = mainCategory.pop();
        // mainCategory.unshift(firstMatchingElement);
    // }
    
    return (
        <section>
            <div class="px-4 md:px-12 my-6 md:my-8 2xl:my-12">
                <h1 class=" text-lg md:text-xl 2xl:text-2xl text-primary mb-4 md:mb-8">{title}</h1>
                <div class="grid grid-cols-2 md:grid-cols-4 gap-x-2 gap-y-4 md:gap-x-6 md:gap-y-8 2xl:gap-x-8 2xl:gap-y-10">
                    {categories.map(category => {
                        return (
                            <CategoryTile category={category} />
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
export default Range